// extracted by mini-css-extract-plugin
export var customServiceModal = "style-mod--custom-service-modal--3LpSL";
export var modalClose = "style-mod--modal-close--3jtKf";
export var modalCloseDesktop = "style-mod--modal-close-desktop--2cISb";
export var modalCloseDesktopDesktop = "style-mod--modal-close-desktop-desktop--2E5Mm";
export var modalCloseDesktopResponsive = "style-mod--modal-close-desktop-responsive--3CFEq";
export var modalCloseResponsive = "style-mod--modal-close-responsive--N_z_m";
export var modalParagraph = "style-mod--modal-paragraph--1etNI";
export var modalTitle = "style-mod--modal-title--37bwt";
export var recoatingServiceModal = "style-mod--recoating-service-modal--2DojR";
export var ccBody = "style-mod--cc-body--18op7";
export var serviceContent = "style-mod--service-content--1dX1M";
export var serviceTitle = "style-mod--service-title--1UOZb";
export var recoatingServiceHead = "style-mod--recoating-service-head--3iji_";
export var recoatingServiceContent = "style-mod--recoating-service-content--16JzU";
export var recoatingServiceAccordionTitle = "style-mod--recoating-service-accordion-title--3z_72";
export var recoatingServiceAccordion = "style-mod--recoating-service-accordion--1he52";
export var accordionList = "style-mod--accordion-list--1L_u8";