import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "assets/svgs/close-btn.svg";
import {
  recoatingService, recoatingServiceModal,  recoatingServiceHead, recoatingServiceAccordion,
  recoatingServiceAccordionTitle, modalClose, ccBody, serviceContent, serviceTitle,
  recoatingServiceContent, accordionList, modalCloseDesktop, modalCloseResponsive, modalContainer,
  customServiceModal
} from "./style.mod.scss";
import RichText from "components/rich-text";
import Image from "components/image";

const RecoatingService = ({ data, children }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const mData = data?.modal && data.modal[0];
  if (!data || !mData) return <></>;

  return (
    <div className={recoatingService}>
      <div className={modalContainer} onClick={handleShow}>
        { children }
      </div>

      <Modal show={show} onHide={handleClose} className={`${customServiceModal} ${recoatingServiceModal}`}>
        <CloseIcon className={`${modalClose} ${modalCloseDesktop}`} onClick={handleClose} />
        <Modal.Body className={ccBody}>
          <CloseIcon className={`${modalClose} ${modalCloseResponsive}`} onClick={handleClose} />
          <div className={recoatingServiceHead}>
            <RichText
              blocks={mData.title}
            />
          </div>
          <div className={recoatingServiceContent}>
            <div className={recoatingServiceAccordion}>
              <p className={recoatingServiceAccordionTitle}>So funktionierts:</p>
              <ul>
                {
                  mData?.iconTimeline?.map((_tim, index)=>(
                    <li key={_tim.id}>
                      <span className={accordionList}>{ index + 1 }</span>
                      <div className={serviceContent}>
                        <p className={serviceTitle}>{ _tim.text }</p>
                        <Image image={_tim.image} alt={_tim?.image?.alt} />
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RecoatingService;
